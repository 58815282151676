/* eslint-disable no-console */
import React, { useContext, useState, useMemo } from 'react'
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  Tooltip
} from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import { PortfolioContext } from 'context/PortfolioContext'
import PortfolioAvgScore from 'components/Portfolio/PortfolioAvgScore'
import {
  Company,
  CompanyRawData,
  CumulativeData,
  TopCategory
} from 'model/portfolio'
import TopStrengthAndOpportunity from 'components/Portfolio/TopStrengthAndOpportunity'
import CompanyDotPlot from 'components/Portfolio/CompanyDotPlot'
import CompanyGroups from 'components/Portfolio/CompanyGroups'
import {
  getCompanies,
  getPortfolioAverageScore,
  getPortfolioCumulativeData,
  getRawCompanies,
  getTopCategories
} from 'utils/portfolio'
import CumulativeDataComponent from 'components/Portfolio/CumulativeDataComponent'

interface PortfolioDashboardProps {
  isDemo: boolean
}

const PortfolioDashboard = ({ isDemo }: PortfolioDashboardProps) => {
  const { portfolioData, organizations } = useContext(PortfolioContext)

  const [companies, setCompanies] = useState<Company[]>([])
  const [portfolioAverageScore, setPortfolioAverageScore] = useState<number>(0)
  const [denominator, setDenominator] = useState<number>(0)
  const [portfolioCumulativeData, setPortfolioCumulativeData] =
    useState<CumulativeData>({} as CumulativeData)
  const [topStrength, setTopStrength] = useState<TopCategory>({} as TopCategory)
  const [topOpportunity, setTopOpportunity] = useState<TopCategory>(
    {} as TopCategory
  )

  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down(900))

  // Set organization data
  useMemo(() => {
    if (organizations && organizations.length > 0) {
      const rawCompanyData: CompanyRawData[] | null = getRawCompanies(
        organizations,
        isDemo
      )
      const companyData: Company[] = getCompanies(rawCompanyData, isDemo)
      setCompanies(companyData)
    }
    if (isDemo) {
      const companyData: Company[] = getCompanies(null, isDemo)
      setCompanies(companyData)
    }
  }, [organizations, isDemo])

  // Set Cumulative Data
  useMemo(() => {
    if (portfolioData || isDemo) {
      const [avgScore, orgCount]: [number, number] = getPortfolioAverageScore(
        portfolioData,
        isDemo
      )
      const cumulativeData: CumulativeData = getPortfolioCumulativeData(
        portfolioData,
        isDemo
      )

      const sectionAverageScores = portfolioData
        ? portfolioData.sectionAverageScores
        : null
      const [strength, opportunity]: [
        TopCategory | undefined,
        TopCategory | undefined
      ] = getTopCategories(sectionAverageScores, isDemo)
      setPortfolioCumulativeData(cumulativeData)
      setPortfolioAverageScore(avgScore)
      setDenominator(orgCount)
      if (strength) {
        setTopStrength(strength)
      }
      if (opportunity) {
        setTopOpportunity(opportunity)
      }
    }
  }, [portfolioData, isDemo])

  return (
    <Box
      sx={{
        maxWidth: 1460,
        width: '100%',
        height: '100vh',
        padding: '40px 0',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ padding: '40px 0 100px 0', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '40px'
          }}
        >
          <Typography variant='h1' sx={{ padding: '10px 0' }}>
            Portfolio
          </Typography>
        </Box>
        <Box sx={{ marginBottom: '60px', fontSize: '20px' }}>
          <p>
            Manage inclusivity at scale across a portfolio of companies or
            groups by seeing insights into members’ platform use, Equity Index
            scores and action plans. Use these insights to track progress and
            see where to best spend your time supporting your broader portfolio.
          </p>
        </Box>

        <Typography variant='h3' sx={{ paddingBottom: '40px' }}>
          Insights
          <Tooltip
            sx={{
              paddingBottom: '8px',
              marginLeft: '4px',
              fontSize: '16px',
              '&:hover': { cursor: 'pointer' }
            }}
            placement='top'
            title='A snapshot of cumulative data across all companies or groups that
            have completed an Equity Index assessment. Companies or groups that
            have not taken an assessment are not factored into any of the data
            shown.'
          >
            <InfoIcon />
          </Tooltip>
        </Typography>
        <Grid container sx={{ marginBottom: '20px' }}>
          <Grid item sm={12} md={3}>
            <Box
              sx={{ height: '94%', paddingRight: isMobileView ? 0 : '20px' }}
            >
              <PortfolioAvgScore
                totalScore={portfolioAverageScore}
                denominator={denominator}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={9}>
            <Box>
              <TopStrengthAndOpportunity
                topStrength={topStrength}
                topOpportunity={topOpportunity}
              />
            </Box>
          </Grid>
        </Grid>
        <CumulativeDataComponent cumulativeDetails={portfolioCumulativeData} />
        <CompanyDotPlot companies={companies} isDemo={isDemo} />
        <CompanyGroups companies={companies} isDemo={isDemo} />
      </Box>
    </Box>
  )
}
export default PortfolioDashboard
