import React from 'react'
import {
  Box,
  BoxProps,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
  Tooltip
} from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import { useSpring, animated, easings } from 'react-spring'
import { Color } from '@mathison-inc/components'

interface CircleProps extends BoxProps {
  shouldReduce: boolean
}

const BlueCircleBackground = styled(Box, {
  shouldForwardProp: prop => prop !== 'shouldReduce'
})<CircleProps>(({ shouldReduce }) => ({
  backgroundColor: '#f0fdff',
  border: '1px solid #acd6dd',
  borderRadius: '50%',
  margin: '10px auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: shouldReduce ? '125px' : '150px',
  width: shouldReduce ? '125px' : '150px'
}))

interface PortfolioAvgScoreProps {
  totalScore: number | undefined | null
  denominator: number
}

const PortfolioAvgScore = ({
  totalScore,
  denominator
}: PortfolioAvgScoreProps) => {
  const theme = useTheme()

  const isMobileView = useMediaQuery(theme.breakpoints.down(900))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200))
  const isTooSmallForCircle = useMediaQuery(
    `(min-width:900px) and (max-width:1000px)`
  )

  const { score } = useSpring({
    from: { score: 0 },
    score: totalScore || 0,
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        paddingTop: '20px',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        border: '1px solid #E5E5E5',
        marginBottom: isMobileView ? '20px' : 0
      }}
    >
      <Typography
        variant='h7'
        component='div'
        sx={{
          textAlign: 'center',
          marginTop: '26px',
          marginBottom: '20px'
        }}
      >
        AVG EQUITY INDEX SCORE
        <Tooltip
          sx={{
            paddingTop: '4px',
            marginLeft: '5px',
            fontSize: '16px',
            '&:hover': { cursor: 'pointer' }
          }}
          placement='top'
          title='The average score (out of 100) of all companies or groups that have completed an Equity Index assessment.'
        >
          <InfoIcon />
        </Tooltip>
      </Typography>

      <BlueCircleBackground shouldReduce={isTooSmallForCircle}>
        {totalScore ? (
          <>
            <animated.h1
              style={{
                fontSize: isSmallScreen ? '80px' : '80px',
                letterSpacing: '-0.1em',
                fontWeight: 200,
                color: Color.grape500
              }}
            >
              {score.to(scorePosition => scorePosition.toFixed(0))}
            </animated.h1>
            <span
              style={{
                color: Color.grape500,
                marginTop: '35px',
                marginLeft: '5px'
              }}
            >
              /100
            </span>
          </>
        ) : (
          <Typography
            sx={{
              fontSize: isSmallScreen ? '85px' : '85px',
              letterSpacing: '-0.05em',
              fontWeight: 400,
              color: '#6c94a9'
            }}
          >
            NA
          </Typography>
        )}
      </BlueCircleBackground>
      {denominator && denominator > 0 ? (
        <Typography
          variant='h7'
          component='div'
          sx={{
            textAlign: 'center',
            marginTop: '26px',
            [theme.breakpoints.down(1509)]: {
              marginBottom: '35px'
            },
            [theme.breakpoints.up(1509)]: {
              marginBottom: '50px'
            }
          }}
        >
          Average of {denominator} scores
        </Typography>
      ) : (
        <Typography
          variant='h7'
          component='div'
          sx={{
            textAlign: 'center',
            color: '#6c94a9',
            marginTop: '26px',
            [theme.breakpoints.down(1509)]: {
              marginBottom: '35px'
            },
            [theme.breakpoints.up(1509)]: {
              marginBottom: '50px'
            }
          }}
        >
          *TBD
        </Typography>
      )}
    </Box>
  )
}
export default PortfolioAvgScore
