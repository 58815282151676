/* eslint-disable no-console */
import React, { useEffect } from 'react'
import {
  Box,
  BoxProps,
  Grid,
  Stack,
  Typography,
  TypographyProps,
  styled
} from '@mui/material'
import { useIncompleteActionsStore } from 'hooks/useIncompleteActionsStore'
import { useCompletedActionsStore } from 'hooks/useCompletedActionsStore'
import { OrgAction } from 'model/portfolio'
import ActionComponent from './ActionComponent'
import { getDemoActions } from 'utils/portfolio'

const Container = styled(Box)<BoxProps>(() => ({
  width: '96%'
}))

const TitleBox = styled(Box)<BoxProps>(() => ({
  width: '40%'
}))

const ActionTitleTypography = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 'bold',
  paddingBottom: '15px'
}))

const LabelTypography = styled(Typography)<TypographyProps>(() => ({
  fontWeight: 'bold',
  textAlign: 'center',
  paddingBottom: '15px'
}))

const DetailsBox = styled(Box)<BoxProps>(() => ({
  width: '60%'
}))

const EmptyBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '200px',
  fontSize: '25px',
  fontWeight: 'bold',
  color: '#6c94a9'
}))

const EmptyActions = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '200px',
  fontSize: '17px',
  fontWeight: 'bold',
  color: '#6c94a9'
}))

interface ActionsSectionProps {
  companyId: string
  isDemo: boolean
}

const ActionsSection = ({ companyId, isDemo }: ActionsSectionProps) => {
  const { incompleteActions, fetchIncompleteOrgActions } =
    useIncompleteActionsStore()

  const { completedActions, fetchCompletedOrgActions } =
    useCompletedActionsStore()

  useEffect(() => {
    if (companyId && !isDemo) {
      fetchIncompleteOrgActions(companyId)
    }
  }, [companyId, fetchIncompleteOrgActions, isDemo])

  useEffect(() => {
    if (companyId) {
      fetchCompletedOrgActions(companyId)
    }
  }, [companyId, fetchCompletedOrgActions])

  const [mockCompleted, mockIncomplete] = getDemoActions()

  const completed: OrgAction[] = isDemo
    ? mockCompleted
    : completedActions[companyId] || []
  const incomplete: OrgAction[] = isDemo
    ? mockIncomplete
    : incompleteActions[companyId] || []

  return (
    <Container>
      {(completed || incomplete) &&
      (completed.length > 0 || incomplete.length > 0) ? (
        <Box>
          {completed && completed.length > 0 ? (
            <Box sx={{ marginBottom: '40px' }}>
              <Stack display='flex' flexDirection='row'>
                <TitleBox>
                  <ActionTitleTypography>
                    Recently Completed Actions
                  </ActionTitleTypography>
                </TitleBox>
                <DetailsBox>
                  <Grid container>
                    <Grid item xs={3}>
                      <LabelTypography>Status</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Created</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Last Updated</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Assignee</LabelTypography>
                    </Grid>
                  </Grid>
                </DetailsBox>
              </Stack>
              <Box>
                {completed.map((action: OrgAction, index: number) => (
                  <ActionComponent
                    key={`${action.title}_${action.createdAt}_${companyId}`}
                    action={action}
                    index={index}
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Box sx={{ marginBottom: '40px' }}>
              <Stack display='flex' flexDirection='row'>
                <TitleBox>
                  <ActionTitleTypography>
                    Recently Completed Actions
                  </ActionTitleTypography>
                </TitleBox>
                <DetailsBox>
                  <Grid container>
                    <Grid item xs={3}>
                      <LabelTypography>Status</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Created</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Last Updated</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Assignee</LabelTypography>
                    </Grid>
                  </Grid>
                </DetailsBox>
              </Stack>
              <EmptyActions>Actions have not been completed</EmptyActions>
            </Box>
          )}
          {incomplete && incomplete.length > 0 ? (
            <Box sx={{ marginBottom: '40px' }}>
              <Stack display='flex' flexDirection='row'>
                <TitleBox>
                  <ActionTitleTypography>
                    Upcoming Actions
                  </ActionTitleTypography>
                </TitleBox>
                <DetailsBox>
                  <Grid container>
                    <Grid item xs={3}>
                      <LabelTypography>Status</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Created</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Last Updated</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Assignee</LabelTypography>
                    </Grid>
                  </Grid>
                </DetailsBox>
              </Stack>
              <Box>
                {incomplete.map((action: OrgAction, index: number) => (
                  <ActionComponent
                    key={`${action.title}_${action.createdAt}_${companyId}`}
                    action={action}
                    index={index}
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Box sx={{ marginBottom: '40px' }}>
              <Stack display='flex' flexDirection='row'>
                <TitleBox>
                  <ActionTitleTypography>
                    Upcoming Actions
                  </ActionTitleTypography>
                </TitleBox>
                <DetailsBox>
                  <Grid container>
                    <Grid item xs={3}>
                      <LabelTypography>Status</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Created</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Last Updated</LabelTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <LabelTypography>Assignee</LabelTypography>
                    </Grid>
                  </Grid>
                </DetailsBox>
              </Stack>
              <EmptyActions>Recent actions have not been created</EmptyActions>
            </Box>
          )}
        </Box>
      ) : (
        <EmptyBox>Recent actions have not been created</EmptyBox>
      )}
    </Container>
  )
}
export default ActionsSection
