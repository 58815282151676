import React from 'react'
import { Box, BoxProps, Typography, styled } from '@mui/material'
import { Company } from 'model/portfolio'
import { MOCK_COMPANIES } from 'utils/portfolio'
import CompanyAccordion from './CompanyAccordion'

const Container = styled(Box)<BoxProps>(() => ({
  padding: '20px',
  backgroundColor: 'white',
  borderRadius: '8px',
  border: '1px solid #E5E5E5',
  marginBottom: '60px'
}))

interface CompanyGroupsProps {
  companies: Company[]
  isDemo: boolean
}

const CompanyGroups = ({ companies, isDemo }: CompanyGroupsProps) => {
  const mockCompanies: Company[] = MOCK_COMPANIES
  return (
    <Box>
      <Typography variant='h3' sx={{ paddingBottom: '40px' }}>
        Companies / Groups
      </Typography>
      {companies && companies.length > 0 ? (
        <Box>
          {isDemo ? (
            <Box>
              {mockCompanies.map((company: Company) => (
                <CompanyAccordion company={company} isDemo={isDemo} />
              ))}
            </Box>
          ) : (
            <Box>
              {companies.map((company: Company) => (
                <CompanyAccordion company={company} isDemo={isDemo} />
              ))}
            </Box>
          )}
        </Box>
      ) : (
        <Container>
          <Box
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#6c94a9',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            Company data is currently being processed.
          </Box>
        </Container>
      )}
    </Box>
  )
}
export default CompanyGroups
