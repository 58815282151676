/* eslint-disable no-console */
import React, { useState, useReducer, useEffect, useContext } from 'react'
import { Dialog, Color, InfoBanner } from 'components'
import { Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { AuthenticationSettings } from 'model/organization'
import { AlertContext } from 'context/AlertContext'
import {
  AccessMethod,
  InitialDomainState,
  DomainState,
  ServiceProvider
} from './constants'
import useStyles from './style'
import DomainLogIn from './DomainLogIn'

interface Props {
  openDialog: boolean
  setOpenDialog: (bool: boolean) => void
  authenticationSettings: AuthenticationSettings
  updateEmployerAuthenticationSettings: (options: any) => any
}
const Overview = (props: Props) => {
  const {
    openDialog,
    setOpenDialog,
    authenticationSettings,
    updateEmployerAuthenticationSettings
  } = props
  const { displayAlertMessage } = useContext(AlertContext)
  const classes = useStyles()
  const [selectedRadio, setSelectedRadio] = useState<any>(
    AccessMethod.INVITATION_ONLY
  )

  const [domainState, dispatch] = useReducer(
    (formState: DomainState, action: any) => {
      return { ...formState, [action.index]: action.value }
    },
    InitialDomainState
  )

  useEffect(() => {
    if (authenticationSettings.strategy) {
      setSelectedRadio(authenticationSettings.strategy)
      if (authenticationSettings.strategy === AccessMethod.DOMAIN_LOCK) {
        dispatch({
          index: 'serviceProvider',
          value: authenticationSettings.idpType
        })
      }
    }
  }, [authenticationSettings])

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      title='Log in settings'
      cancelProps={{
        onClick: () => {
          setOpenDialog(false)
        }
      }}
      confirmProps={{
        text: 'Save',

        disabled: domainState.serviceProvider === ServiceProvider.Select,
        onClick: async () => {
          const result: any = {}
          if (selectedRadio === AccessMethod.INVITATION_ONLY) {
            result.strategy = AccessMethod.INVITATION_ONLY
          } else {
            result.strategy = AccessMethod.DOMAIN_LOCK
            result.idpType = domainState.serviceProvider
          }
          const {
            data: { updateEmployerAuthenticationSetting }
          } = await updateEmployerAuthenticationSettings({
            variables: { input: result }
          })
          // Todo: Once BE is ready, do something with the response
          console.log(updateEmployerAuthenticationSetting)
          setOpenDialog(false)
          displayAlertMessage('Log in settings saved.')
        }
      }}
    >
      <Typography
        variant='body2'
        color={Color.black60}
        className={classes.subtitle}
      >
        Choose how staff members access your organization.
      </Typography>
      <RadioGroup
        defaultValue='female'
        className={classes.ratioGroup}
        value={selectedRadio}
        onChange={(e, value) => {
          setSelectedRadio(value)
        }}
      >
        <FormControlLabel
          value={AccessMethod.INVITATION_ONLY}
          control={<Radio size='small' />}
          label={
            <Typography variant='body1Medium' color={Color.black80}>
              Invite only
            </Typography>
          }
        />
        <div className={classes.indentContainer}>
          <Typography
            variant='body2'
            color={Color.black60}
            className={classes.description}
          >
            Only staff you invite will be able to access your organization on
            Mathison. The account must be created using the email at which the
            invitation was sent.
          </Typography>
        </div>
        <FormControlLabel
          value={AccessMethod.DOMAIN_LOCK}
          control={<Radio size='small' />}
          label={
            <Typography variant='body1Medium' color={Color.black80}>
              Domain log in
            </Typography>
          }
        />
        <div className={classes.indentContainer}>
          <Typography
            variant='body2'
            color={Color.black60}
            className={classes.description}
          >
            Anyone with an email address at your chosen domain can log in. All
            new staff are assigned as a Viewer, you can edit permissions later.
          </Typography>
          <InfoBanner
            sx={{ marginBottom: '20px' }}
            multiline
            type='info'
            content={
              <Typography variant='body2' lineHeight='20px' paragraph={false}>
                If you’re upgrading from{' '}
                <Typography
                  variant='body2'
                  fontWeight={700}
                  paragraph={false}
                  component='span'
                >
                  Invite only
                </Typography>
                , staff that created an account using an email address that
                isn’t at your domain will need to create an account with an
                email address at your domain.
              </Typography>
            }
          />
          {selectedRadio === AccessMethod.DOMAIN_LOCK && (
            <DomainLogIn
              dispatch={dispatch}
              authenticationSettings={authenticationSettings}
              domainState={domainState}
            />
          )}
        </div>
      </RadioGroup>
    </Dialog>
  )
}
export default Overview
