import React from 'react'
import { Box, BoxProps, Grid, Typography, styled, Tooltip } from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import { Company } from 'model/portfolio'
import CompanyDot from './CompanyDot'

const Container = styled(Box)<BoxProps>(() => ({
  padding: '20px',
  backgroundColor: 'white',
  borderRadius: '8px',
  border: '1px solid #E5E5E5',
  marginBottom: '60px'
}))

const LabelBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px',
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'uppercase'
}))

const Box1 = styled(Box)<BoxProps>(() => ({
  backgroundColor: '#DAF0F7',
  height: '100px'
}))

const Box2 = styled(Box)<BoxProps>(() => ({
  backgroundColor: '#D5E3F0',
  height: '100px'
}))

const Box3 = styled(Box)<BoxProps>(() => ({
  backgroundColor: '#C8D9F0',
  height: '100px'
}))

const Box4 = styled(Box)<BoxProps>(() => ({
  backgroundColor: '#B4CBF0',
  height: '100px'
}))

interface CompanyDotPlotProps {
  companies: Company[]
  isDemo: boolean
}

const CompanyDotPlot = ({ companies, isDemo }: CompanyDotPlotProps) => {
  return (
    <Box>
      <Typography variant='h3' sx={{ paddingBottom: '40px' }}>
        Development Stage
        <Tooltip
          sx={{
            paddingBottom: '8px',
            marginLeft: '4px',
            fontSize: '16px',
            '&:hover': { cursor: 'pointer' }
          }}
          placement='top'
          title='See where your companies or groups are on their inclusivity journey by
          seeing their assessment score on a 0-100 scale. These stages are
          helpful to understand their maturity level and how you can best
          support them.'
        >
          <InfoIcon />
        </Tooltip>
      </Typography>
      <Container>
        {companies && companies.length > 0 ? (
          <Box sx={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={3}>
                <LabelBox>Discovering</LabelBox>
              </Grid>
              <Grid item xs={3}>
                <LabelBox>Aware</LabelBox>
              </Grid>
              <Grid item xs={3}>
                <LabelBox>Engaged</LabelBox>
              </Grid>
              <Grid item xs={3}>
                <LabelBox>Leading</LabelBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3}>
                <Box1 />
              </Grid>
              <Grid item xs={3}>
                <Box2 />
              </Grid>
              <Grid item xs={3}>
                <Box3 />
              </Grid>
              <Grid item xs={3}>
                <Box4 />
              </Grid>
            </Grid>
            <Box sx={{ position: 'relative' }}>
              {companies.map((company: Company) => (
                <CompanyDot
                  key={`${company.name}_stage`}
                  id={company.id}
                  isDemo={isDemo}
                  name={company.name}
                  logoUrl={company.logoUrl}
                />
              ))}
            </Box>
            <Grid container sx={{ marginLeft: '-8px' }}>
              <Grid item xs={3}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    sx={{ fontSize: '13px', paddingLeft: '5px' }}
                  >
                    0
                  </Grid>
                  <Grid item xs={6} sx={{ color: 'white', fontSize: '13px' }}>
                    12.5
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={6} sx={{ fontSize: '13px' }}>
                    25
                  </Grid>
                  <Grid item xs={6} sx={{ color: 'white', fontSize: '13px' }}>
                    37.5
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={6} sx={{ fontSize: '13px' }}>
                    50
                  </Grid>
                  <Grid item xs={6} sx={{ color: 'white', fontSize: '13px' }}>
                    62.5
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={6} sx={{ fontSize: '13px' }}>
                    75
                  </Grid>
                  <Grid item xs={6} sx={{ color: 'white', fontSize: '13px' }}>
                    87.5
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ fontSize: '13px', position: 'relative' }}>
              <span
                style={{ position: 'absolute', right: '-10px', top: '-18px' }}
              >
                100
              </span>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#6c94a9',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            Company data is currently being processed.
          </Box>
        )}
      </Container>
    </Box>
  )
}
export default CompanyDotPlot
