import React from 'react'
import {
  Box,
  BoxProps,
  Grid,
  Typography,
  TypographyProps,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { CumulativeData } from 'model/portfolio'

const Container = styled(Box)<BoxProps>(() => ({
  padding: '10px',
  backgroundColor: 'white',
  borderRadius: '8px',
  border: '1px solid #E5E5E5',
  marginBottom: '60px',
  minHeight: '75px'
}))

const DataContainer = styled(Box)<BoxProps>(() => ({
  fontSize: '25px',
  fontWeight: 'bold'
}))

interface LabelTypographyProps extends TypographyProps {
  isMobile: boolean
}

const LabelTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isMobile'
})<LabelTypographyProps>(({ isMobile }) => ({
  fontVariant: 'body1',
  fontSize: isMobile ? '10px' : '16px',
  fontWeight: 'bold',
  height: '30px'
}))

interface CumulativeDataComponentProps {
  cumulativeDetails: CumulativeData
}

const CumulativeDataComponent = ({
  cumulativeDetails
}: CumulativeDataComponentProps) => {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down(900))

  return (
    <Box>
      <Container>
        {cumulativeDetails && cumulativeDetails.totalCompanies > 0 ? (
          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
              <LabelTypography isMobile={isMobileView}>
                Companies / Groups
              </LabelTypography>
              <DataContainer>{cumulativeDetails.totalCompanies}</DataContainer>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
              <LabelTypography isMobile={isMobileView}>Users</LabelTypography>
              <DataContainer>{cumulativeDetails.totalUsers}</DataContainer>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
              <LabelTypography isMobile={isMobileView}>
                Total Goals
              </LabelTypography>
              <DataContainer>{cumulativeDetails.totalGoals}</DataContainer>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
              <LabelTypography isMobile={isMobileView}>
                Completed Goals
              </LabelTypography>
              <DataContainer>{cumulativeDetails.completedGoals}</DataContainer>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '75px'
            }}
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: 'bold', color: '#6c94a9' }}
            >
              Cumulative data is currently being processed.
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  )
}
export default CumulativeDataComponent
