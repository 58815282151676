import React from 'react'
import Grid from '@mui/material/Grid'
import StatisticsBlock from './StatisticsBlock'
import TakeAction from './TakeAction'
import Events from './Events'
import CategoryContents from './CategoryContents'

const CompanyDashboard = () => {
  return (
    <Grid item container>
      <TakeAction />
      <Events />
      <StatisticsBlock />
      <CategoryContents />
    </Grid>
  )
}
export default CompanyDashboard
