/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_PORTFOLIO_DATA } from 'gql/portfolio'
import {
  IPortfolioOrganization,
  IPortfolioDashboardQuery
} from 'model/portfolio'

export const usePortfolio = () => {
  const {
    data,
    loading: portfolioIsLoading,
    error: portfolioError
  } = useQuery(GET_PORTFOLIO_DATA)

  const [portfolioData, setPortfolioData] =
    useState<IPortfolioDashboardQuery | null>(null)
  const [organizations, setOrganizations] = useState<IPortfolioOrganization[]>(
    []
  )

  useEffect(() => {
    if (data?.portfolioDashboardQuery) {
      setPortfolioData(data?.portfolioDashboardQuery)
    }
    if (
      data &&
      data.portfolioOrganizations &&
      data.portfolioOrganizations.length > 0
    ) {
      setOrganizations(data.portfolioOrganizations)
    }
  }, [data])

  return {
    portfolioData,
    portfolioError,
    portfolioIsLoading,
    organizations
  }
}
