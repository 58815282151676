/* eslint-disable no-console */
import React from 'react'
import { Stack, Avatar, Typography } from '@mui/material'
import { getFormattedActionDate, stringAvatar } from 'utils/portfolio'

interface LogoTitleProps {
  companyName: string
  companyLogo: string | undefined
  updatedAt: string
}

const LogoTitle = ({ companyName, companyLogo, updatedAt }: LogoTitleProps) => {
  const completedDate: string = getFormattedActionDate(updatedAt)

  return (
    <Stack direction='row' spacing={2} sx={{ paddingLeft: '10px' }}>
      {companyLogo ? (
        <Avatar
          alt={companyName}
          src={companyLogo}
          sx={{ width: 56, height: 56, marginTop: '5px' }}
        />
      ) : (
        <Avatar {...stringAvatar(companyName)} />
      )}
      <Stack direction='column'>
        <Typography
          sx={{
            fontSize: '28px',
            fontWeight: 'bold',
            letterSpacing: '-0.025em'
          }}
        >
          {companyName}
        </Typography>
        <Typography
          sx={{
            fontSize: '0.75rem',
            fontWeight: 'bold'
          }}
        >
          {`Latest Assessment Completed: ${completedDate}`}
        </Typography>
      </Stack>
    </Stack>
  )
}
export default LogoTitle
