/* eslint-disable no-console */
import { useReducer, useCallback, useContext } from 'react'
import { MicroServiceContext } from 'context/MicroService'
import { GET_ORG_ASSESSMENT_DATA } from 'gql/portfolio'
import { Assessment } from 'model/portfolio'

// State type: Maps employerId to their latest assessment
export interface AssessmentsState {
  [employerId: string]: Assessment
}

// Action types
type ActionType = 'ADD_SCORE'

// Action interface for the reducer
interface AssessmentsReducerAction {
  type: ActionType
  payload: {
    employerId: string
    assessment: Assessment
  }
}

// Reducer to manage assessments
const assessmentsReducer = (
  state: AssessmentsState,
  action: AssessmentsReducerAction
): AssessmentsState => {
  switch (action.type) {
    case 'ADD_SCORE': {
      const { employerId, assessment } = action.payload
      return {
        ...state,
        [employerId]: assessment
      }
    }
    default:
      return state
  }
}

// Hook: useAssessmentsStore
export const useAssessmentsStore = () => {
  const { graphqlRequest } = useContext(MicroServiceContext)
  const [state, dispatch] = useReducer(
    assessmentsReducer,
    {} as AssessmentsState
  )

  // Fetches the latest assessment for a given employerId and updates state
  const fetchLatestAssessment = useCallback(
    async (employerId: string) => {
      // Avoid fetching if we already have the assessment
      if (state[employerId]) return

      try {
        const response = await graphqlRequest(GET_ORG_ASSESSMENT_DATA, {
          id: employerId
        })
        const assessment: Assessment =
          response.data.portfolioOrganizationLatestAssessment

        if (assessment) {
          dispatch({
            type: 'ADD_SCORE',
            payload: { employerId, assessment }
          })
        }
      } catch (error) {
        console.error('Error fetching latest assessment:', error)
      }
    },
    [state, graphqlRequest]
  )

  return {
    assessments: state, // All fetched assessments
    fetchLatestAssessment // Function to fetch latest assessment by employerId
  }
}
