/* eslint-disable no-console */
import { useReducer, useCallback, useContext } from 'react'
import { MicroServiceContext } from 'context/MicroService'
import { GET_ORG_ASSESSMENT_DATA } from 'gql/portfolio'

// State type: Maps employerId to their latest assessment
export interface ScoresState {
  [employerId: string]: number
}

// Action types
type ActionType = 'ADD_SCORE'

// Action interface for the reducer
interface ScoresReducerAction {
  type: ActionType
  payload: {
    employerId: string
    score: number
  }
}

// Reducer to manage scores
const scoresReducer = (
  state: ScoresState,
  action: ScoresReducerAction
): ScoresState => {
  switch (action.type) {
    case 'ADD_SCORE': {
      const { employerId, score } = action.payload
      return {
        ...state,
        [employerId]: score
      }
    }
    default:
      return state
  }
}

// Hook: useScores
export const useScoresStore = () => {
  const { graphqlRequest } = useContext(MicroServiceContext)
  const [state, dispatch] = useReducer(scoresReducer, {} as ScoresState)

  // Fetches the latest assessment for a given employerId and updates state
  const fetchScores = useCallback(
    async (employerId: string) => {
      // Avoid fetching if we already have the assessment
      if (state[employerId]) return

      try {
        const response = await graphqlRequest(GET_ORG_ASSESSMENT_DATA, {
          id: employerId
        })
        const score: number | undefined =
          response.data.portfolioOrganizationLatestAssessment.acquiredScore ||
          undefined

        if (score) {
          dispatch({
            type: 'ADD_SCORE',
            payload: { employerId, score }
          })
        }
      } catch (error) {
        console.error('Error fetching latest score:', error)
      }
    },
    [state, graphqlRequest]
  )

  return {
    scores: state, // All fetched assessments
    fetchScores // Function to fetch latest assessment by employerId
  }
}
