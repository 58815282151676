/* eslint-disable no-console */
import React from 'react'
import { Box, Grid } from '@mui/material'
import { CategoryScores } from 'model/portfolio'
import CategoryBox from './CategoryBox'

interface CategoryHeaderProps {
  categoryScores: CategoryScores | undefined
}

const CategoryHeader = ({ categoryScores }: CategoryHeaderProps) => {
  const hasScore: boolean = !!categoryScores

  return (
    <Box sx={{ paddingBottom: '20px', width: '100%' }}>
      <Grid
        container
        flexDirection='row'
        sx={{
          minHeight: '80px',
          alignItems: 'center'
        }}
      >
        <CategoryBox
          category='defining-tracking'
          index={0}
          score={hasScore ? categoryScores?.dtScore || 0 : 'NA'}
        />
        <CategoryBox
          category='attracting-sourcing'
          index={1}
          score={hasScore ? categoryScores?.asScore || 0 : 'NA'}
        />
        <CategoryBox
          category='interviewing-engaging'
          index={2}
          score={hasScore ? categoryScores?.ieScore || 0 : 'NA'}
        />
        <CategoryBox
          category='onboarding-advancing'
          index={3}
          score={hasScore ? categoryScores?.oaScore || 0 : 'NA'}
        />
      </Grid>
    </Box>
  )
}
export default CategoryHeader
