import React, { useMemo } from 'react'
import { Box, BoxProps, Grid, Typography, styled } from '@mui/material'
import { Color } from '@mathison-inc/components'
import ProgressBar from './ProgressBar'
import ProgressBenchmark from './ProgressBenchmark'

const CATEGORY_BACKGROUND_COLOR: { [key: string]: string } = {
  'defining-tracking': '#F8EBF3',
  'attracting-sourcing': '#ECE7F6',
  'interviewing-engaging': '#E6F4F4',
  'onboarding-advancing': '#E1EAFF'
}

const Background = styled(Grid)(({ color }: { color: string }) => ({
  backgroundColor: color,
  width: '95%',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px'
}))
const ScoreIndicatorBar = styled(Box)<BoxProps>(() => ({
  borderRadius: '12px'
}))

const ProgressBarText = styled(Typography)({
  color: '#050B0E',
  marginLeft: '14px'
})

const BarWrapper = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  width: '100%'
})

interface IProps {
  benchmarkScore: number | undefined
  benchmarkScoreLoading: boolean
  category: string | undefined
  currentScore: number | undefined
}

const TopComponentProgressBar = ({
  benchmarkScore,
  benchmarkScoreLoading,
  category,
  currentScore
}: IProps) => {
  const color = useMemo(
    () =>
      category && currentScore && currentScore > 0
        ? CATEGORY_BACKGROUND_COLOR[category]
        : '#F7F7F7',
    [category, currentScore]
  )

  return (
    <Box>
      <BarWrapper>
        <Background color={color}>
          <ProgressBar category={category} currentScore={currentScore} />
        </Background>
        <ScoreIndicatorBar>
          {currentScore && currentScore > 0 && (
            <ProgressBarText variant='h5'>
              {currentScore}
              <span
                style={{
                  marginTop: '35px',
                  fontSize: '12px'
                }}
              >
                /25
              </span>
            </ProgressBarText>
          )}
        </ScoreIndicatorBar>
      </BarWrapper>
      {benchmarkScore && !benchmarkScoreLoading && (
        <ProgressBenchmark benchmarkScore={benchmarkScore} />
      )}
    </Box>
  )
}
export default TopComponentProgressBar
