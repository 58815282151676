/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useScoresStore } from 'hooks/useScoresStore'
import { Box, BoxProps, styled, Typography } from '@mui/material'
import { animated, easings, useSpring } from 'react-spring'
import { getDemoScoreById, stringAvatar } from 'utils/portfolio'

const StyledBox = styled(Box)<BoxProps>(() => ({
  width: '100%',
  ':hover': {
    cursor: 'pointer'
  }
}))

const AvatarCircle = styled(animated.div)({
  position: 'absolute',
  top: '-60px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '25px',
  width: '25px',
  fontSize: '10px',
  color: 'white',
  overflow: 'hidden'
})

const LogoTooltip = styled(animated.div)({
  position: 'absolute',
  top: '-115px',
  backgroundColor: 'black',
  color: 'white',
  padding: '5px',
  height: '30px',
  width: '100px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: '10px',
  transition: 'opacity 0.3s ease-in-out',
  zIndex: '9999',
  borderRadius: '5%',
  '&.visible': {
    opacity: '75%'
  },
  '&.hidden': {
    opacity: '0%'
  }
})

interface CompanyDotProps {
  id: string
  name: string
  logoUrl: string | undefined
  isDemo: boolean
}

const CompanyDot = ({ id, name, logoUrl, isDemo }: CompanyDotProps) => {
  const { scores, fetchScores } = useScoresStore()
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const tooltipName: string = name.toUpperCase()

  useEffect(() => {
    if (id && !isDemo) {
      fetchScores(id)
    }
  }, [id, fetchScores, isDemo])

  const score: number = isDemo ? getDemoScoreById(id) : scores[id]

  // Normalize the current score to a percentage based on the max width of the Foreground
  const currScorePercentage = score ? score - 0.5 : -0.5
  const tooltipAnimation = useSpring({
    from: {
      left: '0%'
    },
    to: {
      left: `${Number(currScorePercentage) - 2.5}%`
    },
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })
  const barAnimation = useSpring({
    from: {
      left: '0%'
    },
    to: {
      left: `${Number(currScorePercentage)}%`
    },
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  const avatarName: string = stringAvatar(name).children
  const avatarColor: string = stringAvatar(name).sx.bgcolor

  return (
    <StyledBox key={name}>
      <LogoTooltip
        style={tooltipAnimation}
        className={isHovered ? 'visible' : 'hidden'}
      >
        <Typography sx={{ fontSize: '10px', padding: 0, margin: 0 }}>
          {tooltipName}
        </Typography>
        <Typography
          sx={{ fontSize: '10px', padding: 0, margin: 0 }}
        >{`score: ${score}`}</Typography>
      </LogoTooltip>
      <AvatarCircle
        style={{
          ...barAnimation,
          backgroundColor: avatarColor,
          border: `1px solid ${avatarColor}`
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {logoUrl ? (
          <img
            alt='logo'
            src={logoUrl}
            style={{
              textAlign: 'center',
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            }}
          />
        ) : (
          <span>{avatarName}</span>
        )}
      </AvatarCircle>
    </StyledBox>
  )
}
export default CompanyDot
