import { format, parseISO } from 'date-fns'
import {
  AssessmentCategory,
  CategoryScores,
  Company,
  CompanyAction,
  CompanyActionsSummary,
  CompanyRawData,
  CumulativeData,
  IPortfolioDashboardQuery,
  IPortfolioOrganization,
  OrgAction,
  SectionScore as SectionAverage,
  TopCategory
} from 'model/portfolio'

const CATEGORY_ID_MAP = {
  dtId: 'defining-tracking',
  oaId: 'onboarding-advancing',
  asId: 'attracting-sourcing',
  ieId: 'interviewing-engaging'
}

const MOCK_TOP_OPPORTUNITY: TopCategory = {
  name: 'attracting-sourcing',
  score: 8,
  month: 'December',
  type: 'opportunity',
  year: '2025'
}

const MOCK_TOP_STRENGTH: TopCategory = {
  name: 'onboarding-advancing',
  score: 12,
  month: 'December',
  type: 'strength',
  year: '2025'
}

const MOCK_COMPANY_COMPLETED_ACTIONS: CompanyAction[] = [
  {
    title:
      "Write a statement that clearly communicates your organization's commitment to diversity & inclusion",
    status: 'Completed',
    created: 'Nov 12, 2024',
    updated: 'Jan 2, 2025',
    assignee: 'John Smith'
  },
  {
    title:
      'Educate and empower recruiters to provide accessibility accommodations',
    status: 'Completed',
    created: 'Oct 29, 2024',
    updated: 'Jan 2, 2025',
    assignee: 'Jane Anderson'
  },
  {
    title:
      'Put policies in place to ensure job descriptions focus on requirements that are most critical for a candidate to thrive',
    status: 'Completed',
    created: 'Dec 18, 2024',
    updated: 'Jan 2, 2025',
    assignee: 'Bill Summers'
  }
]

const MOCK_ORG_COMPLETED_ACTIONS: OrgAction[] = [
  {
    employerId: '',
    id: 'abcd',
    title:
      "Write a statement that clearly communicates your organization's commitment to diversity & inclusion",
    category: 'defining_tracking',
    createdAt: '2024-03-15T17:52:20Z',
    updatedAt: '2024-11-02T19:23:39Z',
    status: 'Completed',
    isCompleted: true,
    effort: null,
    endDate: null,
    owner: {
      id: '1234',
      name: 'John Smith',
      primaryEmail: 'john.smith@abc.com'
    }
  },
  {
    employerId: '',
    id: 'abcd',
    title:
      'Educate and empower recruiters to provide accessibility accommodations',
    category: 'defining_tracking',
    createdAt: '2024-03-15T17:52:20Z',
    updatedAt: '2024-11-02T19:23:39Z',
    status: 'Completed',
    isCompleted: true,
    effort: null,
    endDate: null,
    owner: {
      id: '1234',
      name: 'John Smith',
      primaryEmail: 'john.smith@abc.com'
    }
  },
  {
    employerId: '',
    id: 'abcd',
    title:
      'Put policies in place to ensure job descriptions focus on requirements that are most critical for a candidate to thrive',
    category: 'defining_tracking',
    createdAt: '2024-03-15T17:52:20Z',
    updatedAt: '2024-11-02T19:23:39Z',
    status: 'Completed',
    isCompleted: true,
    effort: null,
    endDate: null,
    owner: {
      id: '1234',
      name: 'John Smith',
      primaryEmail: 'john.smith@abc.com'
    }
  }
]

const MOCK_ORG_INCOMPLETE_ACTIONS: OrgAction[] = [
  {
    employerId: '',
    id: 'abcd',
    title:
      'Create a structured interview process for some roles and share broadly',
    category: 'defining_tracking',
    createdAt: '2024-03-15T17:52:20Z',
    updatedAt: '2024-11-02T19:23:39Z',
    status: 'To Do',
    isCompleted: false,
    effort: null,
    endDate: null,
    owner: {
      id: '1234',
      name: 'John Smith',
      primaryEmail: 'john.smith@abc.com'
    }
  },
  {
    employerId: '',
    id: 'abcd',
    title: 'Consider measuring and tracking employee diversity data',
    category: 'defining_tracking',
    createdAt: '2024-03-15T17:52:20Z',
    updatedAt: '2024-11-02T19:23:39Z',
    status: 'To Do',
    isCompleted: false,
    effort: null,
    endDate: null,
    owner: {
      id: '1234',
      name: 'John Smith',
      primaryEmail: 'john.smith@abc.com'
    }
  },
  {
    employerId: '',
    id: 'abcd',
    title:
      'Establish Employee Resource Groups, Business Resource Groups or affinity groups',
    category: 'defining_tracking',
    createdAt: '2024-03-15T17:52:20Z',
    updatedAt: '2024-11-02T19:23:39Z',
    status: 'To Do',
    isCompleted: false,
    effort: null,
    endDate: null,
    owner: {
      id: '1234',
      name: 'John Smith',
      primaryEmail: 'john.smith@abc.com'
    }
  }
]

const MOCK_COMPANY_CURRENT_ACTIONS: CompanyAction[] = [
  {
    title: 'Consider measuring and tracking employee diversity data',
    status: 'On Track',
    created: 'Dec 12, 2024',
    updated: 'Jan 2, 2025',
    assignee: 'John Smith'
  },
  {
    title:
      'Create a structured interview process for some roles and share broadly',
    status: 'On Track',
    created: 'Jan 7, 2024',
    updated: 'Feb 5, 2025',
    assignee: 'Jane Anderson'
  },
  {
    title:
      'Establish Employee Resource Groups, Business Resource Groups or affinity groups',
    status: 'To Do',
    created: 'Feb 12, 2024',
    updated: 'Feb 12, 2025',
    assignee: 'Bill Summers'
  }
]

const MOCK_COMPANY_CUMULATIVE_DATA: CumulativeData = {
  totalCompanies: 3,
  totalUsers: 30,
  completedGoals: 10,
  totalGoals: 12
}

const MOCK_CATEGORY_SCORES = [
  {
    id: '12345',
    categoryScores: {
      dtScore: 16,
      asScore: 12,
      ieScore: 15,
      oaScore: 19
    }
  },
  {
    id: '67891',
    categoryScores: {
      dtScore: 5,
      asScore: 5,
      ieScore: 5,
      oaScore: 5
    }
  },
  {
    id: '98765',
    categoryScores: {
      dtScore: 8,
      asScore: 8,
      ieScore: 9,
      oaScore: 11
    }
  }
]

export const MOCK_COMPANIES: Company[] = [
  {
    id: '12345',
    name: 'changesights.ai',
    score: 62,
    logoUrl:
      'https://www.datocms-assets.com/54533/1739760186-chagesights_logo.png'
  },
  {
    id: '67891',
    name: 'Mathison',
    score: 20,
    logoUrl: 'https://www.datocms-assets.com/54533/1739760205-mathison_logo.png'
  },
  {
    id: '98765',
    name: 'octahedron',
    score: 36,
    logoUrl:
      'https://www.datocms-assets.com/54533/1739760195-octahedron_logo.png'
  }
]

export const getDemoScoreById = (id: string): number => {
  return (
    MOCK_COMPANIES.find((company: Company) => company.id === id)?.score || 0
  )
}

export const getDemoActions = (): [OrgAction[], OrgAction[]] => {
  return [MOCK_ORG_COMPLETED_ACTIONS, MOCK_ORG_INCOMPLETE_ACTIONS]
}

export const getFormattedActionDate = (isoString: string): string => {
  if (isoString) {
    return format(parseISO(isoString), 'MMM d, yyyy')
  }
  return ''
}

export const getFormattedStatus = (
  status: string | null | undefined
): string => {
  if (!status) {
    return 'NA'
  }

  switch (status) {
    case 'to_do':
      return 'To Do'
    case 'completed':
      return 'Completed'
    case 'off_track':
      return 'Off Track'
    case 'on_track':
      return 'On Track'
    case 'archived':
      return 'Archived'
    default:
      return status
  }
}

export const getRawCompanies = (
  orgs: IPortfolioOrganization[],
  demoEnabled: boolean
): CompanyRawData[] | null => {
  if (demoEnabled) {
    return null
  }

  const companyRawDataArray: CompanyRawData[] = []

  orgs.forEach((org: IPortfolioOrganization) => {
    if (org.id) {
      const companyData: CompanyRawData = {
        id: org.id || '',
        name: org.name || '',
        logoS3Key: org.logoS3Key || ''
      }
      companyRawDataArray.push(companyData)
    }
  })

  return companyRawDataArray
}

export const getCompanies = (
  rawCompanies: CompanyRawData[] | null,
  demoEnabled: boolean
): Company[] => {
  const companies: Company[] = []
  if (demoEnabled) {
    return MOCK_COMPANIES
  }

  if (rawCompanies && rawCompanies.length > 0) {
    // Convert CompanyRawData array to Company array
    rawCompanies.forEach((rawCompany: CompanyRawData) => {
      // construct category scores
      const company: Company = {
        id: rawCompany.id,
        name: rawCompany.name,
        logoUrl: rawCompany.logoS3Key
      }
      companies.push(company)
    })
  }

  return companies
}

export const getCategoryScores = (
  companyId: string,
  categories: AssessmentCategory[],
  isDemo: boolean
): CategoryScores => {
  if (isDemo) {
    return (
      MOCK_CATEGORY_SCORES.find(category => category.id === companyId)
        ?.categoryScores || MOCK_CATEGORY_SCORES[0].categoryScores
    )
  }

  const categoryScores: CategoryScores = {
    dtScore: 0,
    oaScore: 0,
    ieScore: 0,
    asScore: 0
  }
  if (categories && categories.length > 0) {
    const dtScore: number =
      categories && categories.length > 0
        ? categories.find(category => category.slug === CATEGORY_ID_MAP.dtId)
            ?.acquiredScore || 0
        : 0
    const oaScore: number =
      categories && categories.length > 0
        ? categories.find(category => category.slug === CATEGORY_ID_MAP.oaId)
            ?.acquiredScore || 0
        : 0
    const ieScore: number =
      categories && categories.length > 0
        ? categories.find(category => category.slug === CATEGORY_ID_MAP.ieId)
            ?.acquiredScore || 0
        : 0
    const asScore: number =
      categories && categories.length > 0
        ? categories.find(category => category.slug === CATEGORY_ID_MAP.asId)
            ?.acquiredScore || 0
        : 0

    categoryScores.asScore = asScore
    categoryScores.dtScore = dtScore
    categoryScores.oaScore = oaScore
    categoryScores.ieScore = ieScore
  }
  return categoryScores
}

const getMinMaxSections = (
  sections: SectionAverage[]
): [SectionAverage | null, SectionAverage | null] => {
  if (sections.length === 0) {
    return [null, null]
  }

  const { min, max } = sections.reduce(
    (acc, section) => {
      if (section.averageScore !== null) {
        // Update the min score if the current score is lower
        if (
          acc.min.averageScore === null ||
          section.averageScore < acc.min.averageScore
        ) {
          acc.min = section
        }
        // Update the max score if the current score is higher
        if (
          acc.max.averageScore === null ||
          section.averageScore > acc.max.averageScore
        ) {
          acc.max = section
        }
      }
      return acc
    },
    { min: sections[0], max: sections[0] } // Initial values
  )

  return [min, max]
}

export const getTopCategories = (
  sections: SectionAverage[] | null,
  demoEnabled: boolean
): [TopCategory, TopCategory] | [undefined, undefined] => {
  if (demoEnabled) {
    return [MOCK_TOP_STRENGTH, MOCK_TOP_OPPORTUNITY]
  }
  if (sections && sections.length > 0) {
    const [min, max]: [SectionAverage | null, SectionAverage | null] =
      getMinMaxSections(sections)

    // Set up TopCategory objects
    const opportunitySection: TopCategory = {
      name: min?.sectionSlug || '',
      score: min?.averageScore ? Math.round(min?.averageScore) : 0,
      type: 'opportunity'
    }

    const strengthSection: TopCategory = {
      name: max?.sectionSlug || '',
      score: max?.averageScore ? Math.round(max?.averageScore) : 0,
      type: 'strength'
    }

    return [strengthSection, opportunitySection]
  }

  return [undefined, undefined]
}

export const getCompanyActionsSummary = (
  demoEnabled: boolean
): CompanyActionsSummary => {
  const actions: CompanyActionsSummary = {
    completed: [],
    current: []
  }

  if (demoEnabled) {
    actions.completed = MOCK_COMPANY_COMPLETED_ACTIONS
    actions.current = MOCK_COMPANY_CURRENT_ACTIONS
  }

  return actions
}

export const getPortfolioAverageScore = (
  portfolioData: IPortfolioDashboardQuery | null,
  demoEnabled: boolean
): [number, number] => {
  if (demoEnabled) {
    return [39, 3]
  }

  if (portfolioData) {
    return [
      portfolioData?.averageScore || 0,
      portfolioData?.organizationsWithSubmittedAssessmentsCount || 0
    ]
  }

  return [0, 0]
}

export const getPortfolioCumulativeData = (
  portfolioData: IPortfolioDashboardQuery | null,
  demoEnabled: boolean
): CumulativeData => {
  const cumulativeData: CumulativeData = {
    totalCompanies: 0,
    totalUsers: 0,
    completedGoals: 0,
    totalGoals: 0
  }
  if (demoEnabled) {
    cumulativeData.totalCompanies = MOCK_COMPANY_CUMULATIVE_DATA.totalCompanies
    cumulativeData.totalUsers = MOCK_COMPANY_CUMULATIVE_DATA.totalUsers
    cumulativeData.completedGoals = MOCK_COMPANY_CUMULATIVE_DATA.completedGoals
    cumulativeData.totalGoals = MOCK_COMPANY_CUMULATIVE_DATA.totalGoals
    return cumulativeData
  }

  if (portfolioData) {
    cumulativeData.totalCompanies = portfolioData.organizationsCount || 0
    cumulativeData.totalUsers = portfolioData.totalUsersCount || 0
    cumulativeData.completedGoals = portfolioData.completedGoalsCount || 0
    cumulativeData.totalGoals = portfolioData.totalGoalsCount || 0
  }

  return cumulativeData
}

const stringToColor = (string: string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const stringAvatar = (name: string) => {
  if (name.includes(' ')) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 56,
        height: 56,
        marginTop: '5px'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase()
    }
  }

  const newName: string = (
    name.charAt(0) + name.slice(1).replace(/[aeiouAEIOU]/g, '')
  ).toUpperCase()

  return {
    sx: {
      bgcolor: stringToColor(newName),
      width: 56,
      height: 56,
      marginTop: '5px'
    },
    children: `${newName.split('')[0][0]}${newName.split('')[1][0]}`
  }
}

export const getLatestActions = (
  actions: OrgAction[],
  count: number = 10
): OrgAction[] => {
  if (actions && actions.length > 0) {
    return actions
      .sort(
        (
          a: { updatedAt: string | number | Date },
          b: { updatedAt: string | number | Date }
        ) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      )
      .slice(0, count)
  }
  return []
}
