/* eslint-disable no-console */
import React, { useReducer, useCallback, useContext } from 'react'
import { MicroServiceContext } from 'context/MicroService'
import { GET_ORG_GOALS } from 'gql/portfolio'
import { OrgAction } from 'model/portfolio'
import { getLatestActions } from 'utils/portfolio'

// State type: Maps employerId to a list of OrgActions
interface ActionsState {
  [employerId: string]: OrgAction[]
}

// Action types
type ActionType = 'ADD_COMPLETED_ORG_ASSESSMENTS'

// Action interface
interface ActionsReducerAction {
  type: ActionType
  payload: {
    employerId: string
    actions: OrgAction[]
  }
}

// Reducer to manage organization actions state
const completedActionsReducer = (
  state: ActionsState,
  action: ActionsReducerAction
): ActionsState => {
  switch (action.type) {
    case 'ADD_COMPLETED_ORG_ASSESSMENTS': {
      const { employerId, actions } = action.payload
      // filter and sort by latest 10 actions
      const sortedActions = getLatestActions(actions)
      return {
        ...state,
        [employerId]: sortedActions
      }
    }
    default:
      return state
  }
}

// Hook: useActions
export const useCompletedActionsStore = () => {
  const { graphqlRequest } = useContext(MicroServiceContext)
  const [state, dispatch] = useReducer(
    completedActionsReducer,
    {} as ActionsState
  )

  // Fetch organization actions by employerId and update state
  const fetchCompletedOrgActions = useCallback(
    async (employerId: string) => {
      // Avoid fetching if we already have the data
      if (state[employerId]) return

      try {
        const response = await graphqlRequest(GET_ORG_GOALS, {
          id: employerId,
          isCompleted: true
        })
        const actions: OrgAction[] =
          response.data.portfolioOrganizationGoals.nodes || []

        dispatch({
          type: 'ADD_COMPLETED_ORG_ASSESSMENTS',
          payload: { employerId, actions }
        })
      } catch (error) {
        console.error('Error fetching organization actions:', error)
      }
    },
    [state, graphqlRequest]
  )

  return {
    completedActions: state, // All organization actions by employerId
    fetchCompletedOrgActions // Function to fetch data for a specific employerId
  }
}
