import React from 'react'
import {
  Stack,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  Grid,
  GridProps,
  styled
} from '@mui/material'
import { OrgAction } from 'model/portfolio'
import { getFormattedActionDate, getFormattedStatus } from 'utils/portfolio'

const TitleBox = styled(Box)<BoxProps>(() => ({
  width: '37%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: '3%'
}))

const DetailsBox = styled(Box)<BoxProps>(() => ({
  width: '60%'
}))

const LabelTypography = styled(Typography)<TypographyProps>(() => ({
  textAlign: 'center'
}))

const SectionGrid = styled(Grid)<GridProps>(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '5px 0'
}))

interface ActionComponentProps {
  action: OrgAction
  index: number
}

const ActionComponent = ({ action, index }: ActionComponentProps) => {
  return (
    <Stack
      display='flex'
      flexDirection='row'
      sx={{
        backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 5px'
      }}
    >
      <TitleBox>
        <Typography noWrap>{action.title}</Typography>
      </TitleBox>
      <DetailsBox>
        <Grid container>
          <SectionGrid item xs={3}>
            <LabelTypography noWrap>
              {getFormattedStatus(action.status)}
            </LabelTypography>
          </SectionGrid>
          <SectionGrid item xs={3}>
            <LabelTypography noWrap>
              {getFormattedActionDate(action.createdAt)}
            </LabelTypography>
          </SectionGrid>
          <SectionGrid item xs={3}>
            <LabelTypography noWrap>
              {getFormattedActionDate(action.updatedAt)}
            </LabelTypography>
          </SectionGrid>
          <SectionGrid item xs={3}>
            <LabelTypography noWrap>
              {action.owner?.name || action.owner?.primaryEmail || 'NA'}
            </LabelTypography>
          </SectionGrid>
        </Grid>
      </DetailsBox>
    </Stack>
  )
}
export default ActionComponent
