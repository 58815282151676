/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useEffect } from 'react'
import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  accordionSummaryClasses,
  AccordionDetails,
  Stack,
  styled
} from '@mui/material'
import { useAssessmentsStore } from 'hooks/useAssessmentsStore'
import { ArrowForwardIosSharp } from '@mui/icons-material'
import { Company, CategoryScores, Assessment } from 'model/portfolio'
import { getCategoryScores, getDemoScoreById } from 'utils/portfolio'
import ScoreCircle from './ScoreCircle'
import LogoTitle from './LogoTitle'
import CategoryHeader from './CategoryHeader'
import ActionsSection from './ActionsSection'

const StyledAccordion = styled(Accordion)<AccordionProps>(() => ({
  borderRadius: '8px',
  border: '1px solid #E5E5E5',
  boxShadow: 'none',
  marginBottom: '10px',
  ':first-of-type': {
    borderRadius: '8px'
  },
  ':last-child': {
    borderRadius: '8px'
  },
  '&::before': {
    display: 'none'
  }
}))

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.03)',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(90deg)'
    },
  [theme.palette.mode === 'dark' ? '&' : '']: {
    backgroundColor: 'rgba(255, 255, 255, .05)'
  }
}))

interface CompanyAccordionProps {
  company: Company
  isDemo: boolean
}

const CompanyAccordion = ({ company, isDemo }: CompanyAccordionProps) => {
  const { assessments, fetchLatestAssessment } = useAssessmentsStore()

  useEffect(() => {
    if (company.id && !isDemo) {
      fetchLatestAssessment(company.id)
    }
  }, [company, fetchLatestAssessment, isDemo])

  const assessment: Assessment = assessments[company.id]

  const demoScore: number | undefined = isDemo
    ? getDemoScoreById(company.id)
    : undefined

  const categoryScores: CategoryScores | undefined = isDemo
    ? getCategoryScores(company.id, [], isDemo)
    : assessment
    ? getCategoryScores(
        company.id,
        assessment.employerAssessmentCategories,
        isDemo
      )
    : undefined

  return (
    <StyledAccordion key={`${company.name}_info`}>
      <StyledAccordionSummary aria-controls='panel1-content' id='panel1-header'>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          sx={{
            width: '100%'
          }}
        >
          {company.name && (
            <LogoTitle
              companyName={company.name}
              companyLogo={company.logoUrl}
              updatedAt={
                assessment ? assessment.updatedAt : '2025-02-15T00:52:57Z'
              }
            />
          )}
          {isDemo ? (
            <ScoreCircle totalScore={demoScore} />
          ) : (
            <ScoreCircle
              totalScore={assessment ? assessment.acquiredScore : undefined}
            />
          )}
        </Stack>
      </StyledAccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <CategoryHeader categoryScores={categoryScores} />
        <ActionsSection companyId={company.id} isDemo={isDemo} />
      </AccordionDetails>
    </StyledAccordion>
  )
}
export default CompanyAccordion
