/* eslint-disable no-console */
import React from 'react'
import { Root, createRoot } from 'react-dom/client'
import {
  StyledEngineProvider,
  ThemeProvider as CoreThemeProvider
} from '@mui/material/styles'
import { theme } from 'components'
import './index.css'
import { StylesProvider, createGenerateClassName } from '@mui/styles'
import { MicroServiceProvider, MicroServiceProps } from 'context/MicroService'
import { AlertProvider } from 'context/AlertContext'
import { ApolloProvider } from '@apollo/client'
import App from './App'

const generateClassName = createGenerateClassName({
  productionPrefix: 'podium',
  disableGlobal: true
})
interface Props {
  container: any
}
let root: Root
const render = (props: MicroServiceProps & Props) => {
  const {
    container,
    currentAccount,
    cmsApiKey,
    displayNotification,
    openContactDialog,
    isFlagEnabled,
    graphqlRequest,
    maestroClient
  } = props
  root = createRoot(
    container
      ? container.querySelector('#podium-root')
      : document.getElementById('podium-root')
  )
  root.render(
    <MicroServiceProvider
      currentAccount={currentAccount}
      cmsApiKey={cmsApiKey}
      displayNotification={displayNotification}
      openContactDialog={openContactDialog}
      isFlagEnabled={isFlagEnabled}
      maestroClient={maestroClient}
      graphqlRequest={graphqlRequest}
    >
      <AlertProvider>
        <StyledEngineProvider injectFirst>
          <ApolloProvider client={maestroClient}>
            <StylesProvider generateClassName={generateClassName}>
              <CoreThemeProvider theme={theme}>
                <App />
              </CoreThemeProvider>
            </StylesProvider>
          </ApolloProvider>
        </StyledEngineProvider>
      </AlertProvider>
    </MicroServiceProvider>
  )
}
export const bootstrap = async () => {
  console.log('app portal react app bootstraped')
}

export const mount = async (props: any) => {
  render(props)
}

export const unmount = async () => {
  root.unmount()
}
export const update = async (props: any) => {
  render(props)
}
