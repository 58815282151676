/* eslint-disable no-console */
import React, { createContext, useMemo } from 'react'
import {
  IPortfolioDashboardQuery,
  IPortfolioOrganization
} from 'model/portfolio'
import { usePortfolio } from 'hooks/usePortfolio'

type PortfolioContextType = {
  portfolioData: IPortfolioDashboardQuery | null
  portfolioIsLoading: boolean
  portfolioError: any
  organizations: IPortfolioOrganization[]
}

const INITIAL_CONTEXT: PortfolioContextType = {
  portfolioData: null,
  portfolioIsLoading: true,
  portfolioError: null,
  organizations: []
}

export const PortfolioContext =
  createContext<PortfolioContextType>(INITIAL_CONTEXT)

export const PortfolioProvider = ({ children }: any): JSX.Element => {
  const { portfolioData, portfolioIsLoading, portfolioError, organizations } =
    usePortfolio()

  const values: PortfolioContextType = useMemo(
    () => ({
      portfolioData,
      portfolioIsLoading,
      portfolioError,
      organizations
    }),
    [portfolioData, portfolioIsLoading, portfolioError, organizations]
  )

  return (
    <PortfolioContext.Provider value={values}>
      {children}
    </PortfolioContext.Provider>
  )
}
