import { gql } from '@apollo/client'

export const GET_PORTFOLIO_DATA = gql`
  query {
    portfolioDashboardQuery {
      activeUsersCount
      totalUsersCount
      organizationsCount
      averageScore
      organizationsWithSubmittedAssessmentsCount
      totalGoalsCount
      completedGoalsCount
      sectionAverageScores {
        sectionSlug
        averageScore
      }
    }

    portfolioOrganizations {
      id
      name
      logoS3Key
    }
  }
`

export const GET_ORG_ASSESSMENT_DATA = `
  query ($id: ID!) {
    portfolioOrganizationLatestAssessment(id: $id) {
      employerId
      id
      acquiredScore
      updatedAt
      employerAssessmentCategories {
        slug
        acquiredScore
      }
    }
  }
`

export const GET_ORG_GOALS = `
  query ($id: ID!, $isCompleted: Boolean) {
    portfolioOrganizationGoals(id: $id, isCompleted: $isCompleted) {
      nodes {
        employerId
        id
        title
        category
        createdAt
        effort
        updatedAt
        status
        isCompleted
        endDate
        owner {
          id
          name
          primaryEmail
        }
      }
    }
  }
`
