import React from 'react'
import {
  Box,
  Chip,
  ChipProps,
  styled,
  useTheme,
  useMediaQuery,
  Typography
} from '@mui/material'
import { useSpring, animated, easings } from 'react-spring'
import { Color } from '@mathison-inc/components'
import { CultureStage } from 'model/portfolio'

const BlueCircleBackground = styled('div')({
  backgroundColor: '#f0fdff',
  border: '1px solid #acd6dd',
  borderRadius: '50%',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50px',
  width: '50px'
})

const StagePill = styled(Chip)<ChipProps>(() => ({
  fontSize: '10px',
  fontWeight: 'bold',
  height: '20px',
  minWidth: '105px',
  borderRadius: '5px'
}))

interface ScoreCircleProps {
  totalScore: number | undefined
}

const ScoreCircle = ({ totalScore }: ScoreCircleProps) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1510))
  const { score } = useSpring({
    from: { score: 0 },
    score: totalScore || 0,
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  const getStage = (companyScore: number): CultureStage => {
    let stage: CultureStage = 'LEADING'
    if (companyScore < 25) stage = 'DISCOVERING'
    if (companyScore >= 25 && companyScore < 50) stage = 'AWARE'
    if (companyScore >= 50 && companyScore < 75) stage = 'ENGAGED'
    return stage
  }

  const getColor = (stage: CultureStage): string => {
    if (!stage || stage === 'TBD') return '#e3eff5'

    switch (stage) {
      case 'DISCOVERING':
        return '#EEC18E'
      case 'AWARE':
        return '#F9ED85'
      case 'ENGAGED':
        return '#daf3d9'
      case 'LEADING':
        return '#E5D0E3'
      default:
        return '#e3eff5'
    }
  }

  const stage = totalScore ? getStage(totalScore) : 'TBD'
  const color = getColor(stage)

  return (
    <Box>
      <BlueCircleBackground>
        {totalScore ? (
          <animated.h1
            style={{
              fontSize: isSmallScreen ? '30px' : '30px',
              letterSpacing: '-0.05em',
              fontWeight: 400,
              color: Color.grape500
            }}
          >
            {score.to(scorePosition => scorePosition.toFixed(0))}
          </animated.h1>
        ) : (
          <Typography
            sx={{
              fontSize: isSmallScreen ? '30px' : '30px',
              letterSpacing: '-0.05em',
              fontWeight: 400,
              color: '#6c94a9'
            }}
          >
            NA
          </Typography>
        )}
      </BlueCircleBackground>
      <StagePill
        label={stage}
        variant='outlined'
        sx={{ backgroundColor: color }}
      />
    </Box>
  )
}
export default ScoreCircle
