/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import React, { useContext, useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  ButtonProps,
  styled,
  Stack
} from '@mui/material'
import { TakeActionProvider } from 'context/TakeActionContext'
import { DashboardProvider } from 'context/DashboardContext'
import { PortfolioProvider } from 'context/PortfolioContext'
import { MicroServiceContext } from 'context/MicroService'
import useStyles from './style'
import CompanyDashboard from './CompanyDashboard'
import PortfolioDashboard from './PortfolioDashboard'

const DashboardButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#3148D3',
  color: '#FFFFFF',
  padding: '11px 20px 11px 13px',
  '&:hover': {
    backgroundColor: '#050B0F'
  }
}))

const Home = (): React.ReactElement => {
  const classes = useStyles()

  // TODO: Get "isFlagEnabled" and "flags" from context for LaunchDarkly
  const { currentAccount, cmsApiKey, isFlagEnabled } =
    useContext(MicroServiceContext)

  const portfolioEnabled: boolean = isFlagEnabled('enable_portfolio') || false
  const portfolioDemoEnabled: boolean =
    isFlagEnabled('enable_portfolio_demo') || false

  // TODO: Get isPortfolio from currentAccount or see if it's a demo account
  const isPortfolio: boolean =
    currentAccount?.isPortfolio || portfolioDemoEnabled

  const [showPortfolio, setShowPortfolio] = useState<boolean>(isPortfolio)

  const handleDashboardChange = () => {
    setShowPortfolio(!showPortfolio)
  }

  if (!cmsApiKey) {
    return <></>
  }

  return (
    <DashboardProvider cmsApiKey={cmsApiKey}>
      <PortfolioProvider>
        <TakeActionProvider>
          <div className={classes.dashboardContainer}>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h1'>
                    Welcome, {`${currentAccount.user.name.split(' ')[0]}`}
                  </Typography>
                  {isPortfolio && portfolioEnabled && (
                    <DashboardButton onClick={handleDashboardChange}>
                      {showPortfolio
                        ? 'View Company Dashboard'
                        : 'View Portfolio'}
                    </DashboardButton>
                  )}
                </Stack>
              </Grid>
              {isPortfolio && portfolioEnabled && showPortfolio ? (
                <PortfolioDashboard isDemo={portfolioDemoEnabled} />
              ) : (
                <CompanyDashboard />
              )}
            </Grid>
          </div>
        </TakeActionProvider>
      </PortfolioProvider>
    </DashboardProvider>
  )
}

export default Home
