/* eslint-disable no-console */
import React from 'react'
import {
  Box,
  Paper,
  PaperProps,
  styled,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  Grid,
  GridProps,
  Alert
} from '@mui/material'
import { TopCategory } from 'model/portfolio'
import TopComponent from './TopComponent'

const DescriptionsContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  boxShadow: 'none',
  border: '1px solid #E5E5E5',
  borderRadius: '8px',
  marginBottom: '20px',
  backgroundColor: '#daf1f7',
  padding: '10px 0'
}))

const SectionGrid = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

interface IProps {
  topStrength: TopCategory | undefined
  topOpportunity: TopCategory | undefined
}

const TopStrengthAndOpportunity = ({ topStrength, topOpportunity }: IProps) => {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down(900))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        marginTop: isMobileView ? '20px' : 0
      }}
    >
      <Stack display='flex' flexDirection='column'>
        <Box sx={{ marginBottom: '20px' }}>
          <Alert severity='info'>
            The Equity Index asks questions in 4 sections, to help figure out
            where you are in achieving your cultural objectives. These sections
            are: Defining & Tracking, Attracting & Sourcing, Interviewing &
            Engaging, and Onboarding & Advancing.
          </Alert>
        </Box>
        <Box sx={{ marginBottom: '20px' }}>
          <TopComponent
            isSmallScreen={isSmallScreen}
            benchmarkScore={
              topStrength ? topStrength.benchmarkScore : undefined
            }
            benchmarkScoreLoading={false}
            currentScore={topStrength ? topStrength.score : undefined}
            previousScore={topStrength ? topStrength.previousScore : undefined}
            category={topStrength ? topStrength.name : undefined}
            type='strength'
            quarterDetails={
              topStrength && topStrength.previousMonth
                ? `From ${topStrength.previousMonth}`
                : ''
            }
          />
        </Box>
        <Box>
          <TopComponent
            isSmallScreen={isSmallScreen}
            benchmarkScore={
              topOpportunity ? topOpportunity.benchmarkScore : undefined
            }
            benchmarkScoreLoading={false}
            currentScore={topOpportunity ? topOpportunity.score : undefined}
            previousScore={
              topOpportunity ? topOpportunity.previousScore : undefined
            }
            category={topOpportunity ? topOpportunity.name : undefined}
            type='opportunity'
            quarterDetails={
              topOpportunity && topOpportunity.previousMonth
                ? `From ${topOpportunity.previousMonth}`
                : ''
            }
          />
        </Box>
      </Stack>
    </Box>
  )
}
export default TopStrengthAndOpportunity
