/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
import React from 'react'
import {
  Grid,
  GridProps,
  Box,
  BoxProps,
  styled,
  Typography
} from '@mui/material'
import { Color, theme } from 'components'
import logo from 'images/card_logo.png'

const setBackgroundColor = (category: string) => {
  switch (category) {
    case 'onboarding-advancing':
      return '#375FC6'
    case 'attracting-sourcing':
      return '#420CA4'
    case 'defining-tracking':
      return '#B83282'
    case 'interviewing-engaging':
      return '#1B8183'
    default:
      return Color.iris500
  }
}

interface StyledGridProps extends GridProps {
  index: number
  length: number
}

const StyledGrid = styled(Grid, {
  shouldForwardProp: prop => prop !== 'index' && prop !== 'length'
})<StyledGridProps>(({ index, length }) => ({
  display: 'flex',
  width:
    length === 3
      ? '33.333333%'
      : length === 2
      ? '50%'
      : length === 4
      ? '25%'
      : '100%',
  padding:
    length === 1
      ? 0
      : (length === 2 || length === 3) && index === 0
      ? '0 5px 0 0'
      : length === 2 && index === 1
      ? '0 0 0 5px'
      : length === 3 && index === 1
      ? '0 5px 0 5px'
      : '0 0 0 5px',
  background: 'transparent',
  [theme.breakpoints.down(1350)]: {
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 0 20px 0'
  }
}))

interface StyledBoxProps extends BoxProps {
  sx?: any
  category: string
}

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'category'
})<StyledBoxProps>(({ category }) => ({
  display: 'flex',
  borderRadius: '8px',
  backgroundColor: setBackgroundColor(category),
  backgroundImage: `url(${logo})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center right',
  backgroundSize: '65px',
  flexGrow: 1,
  flexDirection: 'column',
  padding: '24px 32px',
  height: '65px',
  width: '100%',
  [theme.breakpoints.down(1350)]: {
    width: '90%'
  }
}))

interface IProps {
  sx?: any
  index: number
  category: string
  score: number | string
}

const CategoryBox = ({ category, sx, index, score }: IProps) => {
  const getCategoryName = (categoryEnum: string): string => {
    switch (categoryEnum) {
      case 'onboarding-advancing':
        return 'Onboarding & Advancing'
      case 'attracting-sourcing':
        return 'Attracting & Sourcing'
      case 'defining-tracking':
        return 'Defining & Tracking'
      case 'interviewing-engaging':
        return 'Interviewing & Engaging'
      default:
        return 'NA'
    }
  }

  return (
    <StyledGrid item sx={sx} index={index} length={4}>
      <StyledBox category={category}>
        <Box>
          <Typography
            variant='h4'
            sx={{
              fontSize: '12px',
              color: Color.white,
              textTransform: 'uppercase',
              lineHeight: '16px',
              letterSpacing: '2.04px'
            }}
          >
            {getCategoryName(category)}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant='h2'
            sx={{
              fontSize: '28px',
              fontWeight: 'bold',
              color: Color.white,
              lineHeight: '24px',
              padding: '20px 0'
            }}
          >
            {score}
            <span
              style={{ fontSize: '12px', fontWeight: 400, paddingLeft: '5px' }}
            >
              /25
            </span>
          </Typography>
        </Box>
      </StyledBox>
    </StyledGrid>
  )
}
export default CategoryBox
